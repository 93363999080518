import { AdConfigDataProps } from '@sport1/types/web'
// import { getPrerollsCount } from './utils'
import { MappedTrackingData } from '@/utils/tracking/TrackingProvider'
import { Type } from '@/types/video'
import { isPureActive } from '@/components/ThirdParty/Piano/util'
import {
    AdSchedule,
    SDGPublisherVastProps,
    SDGPublisherVastResult,
    VastType,
    VideoPlayerAdProps,
    VideoZoneType,
} from '@/types/vastAd'
import Config from '@/utils/Config'

type getVastUrlProps = SDGPublisherVastProps & {
    ad?: Partial<AdConfigDataProps>
    trackingData?: MappedTrackingData
}

/**
 * Flags to overwrite the API
 * API and these flags need to be true to be active
 */
const prerollEnabled = true
const midrollEnabled = false
const postrollEnabled = false

const MAX_WAIT_TIME = 1000

const configureAd = (
    ad: Partial<AdConfigDataProps> | undefined
): Partial<AdConfigDataProps> | undefined => {
    if (!ad) {
        return
    }
    return {
        ...ad,
        prerollEnabled: prerollEnabled && ad.prerollEnabled,
        midrollEnabled: midrollEnabled && ad.midrollEnabled,
        postrollEnabled: postrollEnabled && ad.postrollEnabled,
    }
}

/**
 * pkv1: adUnit3
 * pkv2: adUnit4
 * pkv3: team
 * pkv4: contentType
 * pkv5: video_program
 *
 * if something's not set, fills up
 */
const createTargetingQueryParameter = (
    ad?: Partial<AdConfigDataProps>,
    trackingData?: MappedTrackingData
) => {
    const targetingValues: Array<string | undefined> = [
        ad?.targeting?.adUnit3,
        ad?.targeting?.adUnit4,
        ad?.targeting?.team,
        ad?.targeting?.contentType,
        trackingData?.['video_program'],
    ]

    if (!targetingValues.filter(el => el).length) {
        return ''
    }

    return `${encodeURIComponent(
        targetingValues
            .map((v, i) => ({
                pkvNumber: i + 1,
                value: v,
            }))
            .filter(el => el.value)
            .map(el => `pkv${el.pkvNumber}=${encodeURIComponent(String(el.value).toLowerCase())}`)
            .join('&')
    )}`
}

/**
 * MUST only be called client side
 */
export const getVastUrl = ({ ad, videozone, trackingData, callback }: getVastUrlProps) => {
    if (typeof window === 'object') {
        let calledBack = false
        let timer: number
        ad = configureAd(ad)

        /**
         * in case the ad server is down: just start the video
         */
        const timeout = setTimeout(() => {
            calledBack = true
            callback({})
        }, MAX_WAIT_TIME)

        window.SDG?.cmd.push(async function () {
            clearTimeout(timeout)
            if (calledBack) {
                return
            }
            calledBack = true

            const tags: SDGPublisherVastResult = await Promise.race([
                window?.SDG?.Publisher?.generateVastUrlsPromise?.({
                    bumper: ad?.prerollEnabled,
                    preroll: ad?.prerollEnabled,
                    midroll: ad?.midrollEnabled,
                    postroll: ad?.postrollEnabled,
                    videozone,
                }),
                new Promise(resolve => {
                    timer = window.setTimeout(() => {
                        resolve({})
                    }, 1000)
                }),
            ])
            clearTimeout(timer)

            /**
             * Ströer ads a placeholder `~sport1kvs~`, we replace this
             * with an url encoded query string with of our targeting
             */
            const targeting = createTargetingQueryParameter(ad, trackingData)
            Object.keys(tags)?.forEach(key => {
                const tag = tags[key as VastType]
                if (tag) {
                    tags[key as VastType] = tag.replace('~sport1kvs~', targeting)
                }
            })

            // eslint-disable-next-line no-console
            console.debug(
                'Ads: SDG?.Publisher?.generateVastUrlsPost({ bumper, preroll, midroll, postroll, videozone })',
                {
                    bumper: ad?.prerollEnabled,
                    preroll: ad?.prerollEnabled,
                    midroll: ad?.midrollEnabled,
                    postroll: ad?.postrollEnabled,
                    videozone,
                },
                tags
            )

            callback?.(tags)
        })
    }
}

const zones: Record<Type | string, VideoZoneType> = {
    VoD: 'vod',
    liveStream: 'live',
    eventStream: 'live',
    undefined: 'vod',
}
export const buildVideoZone = (type: Type | undefined): VideoZoneType => zones[String(type)]

type ConfigureVastProps = {
    callback: (adConfig: VideoPlayerAdProps) => void
    type?: Type
    ad?: Partial<AdConfigDataProps>
    duration?: number
    trackingData?: MappedTrackingData
}

export const configureVast = ({
    ad,
    // duration,
    type,
    trackingData,
    callback,
}: ConfigureVastProps) => {
    ad = configureAd(ad)
    if (
        !isPureActive() &&
        Config.ADS_ACTIVE &&
        (ad?.prerollEnabled || ad?.midrollEnabled || ad?.postrollEnabled)
    ) {
        getVastUrl({
            ad,
            trackingData,
            videozone: buildVideoZone(type),
            callback: tags => {
                const adConfig: VideoPlayerAdProps = {}
                const isLivestream = type === 'liveStream'
                /** frequency 0 on Livestream, otherwise the prerolls will endlessly play
                 *  default values of rules are frequency 1 and startOn 1
                 *  for more information https://docs.jwplayer.com/players/reference/advertising-config-ref */
                const rules = isLivestream
                    ? {
                          rules: { frequency: 0, startOn: 0 },
                      }
                    : undefined

                adConfig.advertising = {
                    client: 'googima',
                    omidSupport: 'auto',
                    maxRedirects: 6,
                    loadVideoTimeout: 9000,
                    schedule: [],
                    ...rules,
                }

                // const testPrerollVast =
                //     'https://pubads.g.doubleclick.net/gampad/ads?iu=/36930962/review_test_video_preroll&description_url=http%3A%2F%2Fwww.sport1.de&tfcd=0&npa=0&sz=640x360%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=&ad_rule=1'
                const testMidrollVast =
                    'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostlongpod&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator='

                // const adsCount = getPrerollsCount({ duration, isLivestream })
                // adConfig.advertising.schedule.push({
                //     offset: 'pre',
                //     tag: testPrerollVast,
                //     ...(adsCount
                //         ? {
                //               custParams: {
                //                   content_dur: adsCount,
                //               },
                //           }
                //         : {}),
                // })

                adConfig.advertising.schedule.push({
                    offset: 0,
                    tag: testMidrollVast,
                    // ...(adsCount
                    //     ? {
                    //         custParams: {
                    //             content_dur: adsCount,
                    //         },
                    //     }
                    //     : {}),
                })

                // if (ad?.midrollEnabled && tags.midroll) {
                //     adConfig.advertising.schedule = adConfig.advertising.schedule.concat(
                //         configureMidRolls(tags.midroll, duration)
                //     )
                // }

                if (ad?.postrollEnabled && tags.postroll && type !== 'liveStream') {
                    adConfig.advertising.schedule.push({ offset: 'post', tag: tags.postroll })
                }

                callback(adConfig)
            },
        })
    } else {
        callback({})
    }
}

export const configureMidRolls = (
    tag: string,
    videoDuration: number | undefined
): Array<AdSchedule> => {
    const MIN_10 = 600
    const MIN_20 = 1200
    const MAX_MIDROLLS = 4

    if (!videoDuration || videoDuration < MIN_20) {
        return []
    }
    const schedule: Array<AdSchedule> = []

    const durationLeft = videoDuration - MIN_10
    const midrolls = Math.min(Math.floor(durationLeft / MIN_10), MAX_MIDROLLS)
    const durationBetweenMidrolls = Math.floor(durationLeft / midrolls)

    let offset = MIN_10
    for (let i = 0; i < midrolls; i += 1) {
        schedule.push({
            offset,
            tag,
        })
        offset += durationBetweenMidrolls
    }
    return schedule
}
